import React, { useState, useRef, useEffect } from "react";

import { Accordion } from "react-bootstrap";

import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";

import ProjectTabSection1 from "components/home/project/ProjectTabSection1";
import ProjectTabSection2 from "components/home/project/ProjectTabSection2";
import ProjectTabSection3 from "components/home/project/ProjectTabSection3";
import ProjectTabSection4 from "components/home/project/ProjectTabSection4";
import ProjectTabSection5 from "components/home/project/ProjectTabSection5";
import ProjectTabSection6 from "components/home/project/ProjectTabSection6";
import ProjectTabSection8 from "components/home/project/ProjectTabSection8";
import ProjectTabSection9 from "components/home/project/ProjectTabSection9";

// data

import { nurseries } from "assets/data/project";

export default function Projects() {
  const [tabName, setTabName] = useState("tab6");

  const stateCarousel = useRef(null);

  const [mapCurrentTab, setMapCurrentTab] = useState(1);

  const [isMobile, setIsMobile] = useState(false);

  const changeTabName = (name) => {
    setTabName(name);
  };

  const responsive = {
    desktop: {
      breakpoint: {
        max: 30000,
        min: 320,
      },
      items: 1,
    },
  };

  const ButtonGroup = ({ next, previous }) => {
    return (
      <div className="carousel-button-group">
        <div className="navigations">
          <button onClick={() => previous()} className="me-3">
            <i className="icon icon-tree-leaf-left"></i>
          </button>
          <button onClick={() => next()}>
            <i className="icon icon-tree-leaf-right"></i>
          </button>
        </div>
      </div>
    );
  };

  useEffect(() => {
    window.innerWidth < 975 ? setIsMobile(true) : setIsMobile(false);
  }, []);

  return (
    <div className="project-section">
      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title">
                <h3 className="text-start">
                  Selected <span className="green">Projects</span>
                </h3>
              </div>
              <div className="accordian-tab-section">
                <div className="row">
                  <div className="aco-tab-section">
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="4">
                        <Accordion.Header
                          onClick={() => {
                            changeTabName("tab3");
                            if (stateCarousel) {
                              stateCarousel.current.goToSlide(0);
                            }
                          }}
                        >
                          {nurseries.tabName}
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="tab-carousel-img">
                            <Carousel
                              afterChange={(
                                previousSlide,
                                { currentSlide, onMove }
                              ) => {
                                setMapCurrentTab(currentSlide - 1);
                              }}
                              ref={stateCarousel}
                              arrows={false}
                              autoPlaySpeed={3000}
                              draggable
                              infinite
                              keyBoardControl
                              minimumTouchDrag={80}
                              partialVisible
                              renderButtonGroupOutside={true}
                              swipeable
                              ssr={false}
                              responsive={responsive}
                              customButtonGroup={<ButtonGroup />}
                            >
                              {nurseries.carousel.map((item) => {
                                return (
                                  <div
                                    className="img-wrapper"
                                    key={item.id}
                                    id={item.id}
                                  >
                                    <img
                                      data-src={item.img}
                                      className="lazyload"
                                      alt={item.alt}
                                    />
                                    <p>
                                      <span>{item.locationName}</span>
                                    </p>
                                  </div>
                                );
                              })}
                            </Carousel>
                          </div>
                          {isMobile ? (
                            <ProjectTabSection3
                              stateCarousel={stateCarousel}
                              mapCurrentTab={mapCurrentTab}
                            />
                          ) : (
                            <></>
                          )}
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="5">
                        <Accordion.Header
                          onClick={() => {
                            changeTabName("tab2");
                          }}
                        >
                          Green Kanha Initiative
                        </Accordion.Header>
                        <Accordion.Body>
                          <p>
                            The thriving ecosystem at Kanha has been created in
                            the last 5 years starting from a barren land. And it
                            is fast becoming a model for best practices in
                            nurturing India’s mega-biodiversity, saving
                            endangered and rare species, creating medicinal
                            gardens, and a sanctuary for wild birds and
                            reptiles.
                          </p>

                          <p>
                            We are developing sacred groves including gardens of
                            edible wild herbs and shrubs, edible wild fruit and
                            tubers, medicinal plants for primary healthcare and
                            wellness, skincare, and haircare.
                          </p>

                          <p>
                            We have adopted the latest technologies with
                            scientific planning and traditional know-how, to
                            create an ex-situ conservation center in Kanha
                            Shanti Vanam replete with resplendent green spaces.
                          </p>

                          <p>
                            Each one, Teach One, How to Plant One has been a
                            significant campaign in this journey, enabling the
                            plantation of more than 100,000 trees on campus.
                            With a team of dedicated volunteers, including the
                            children of Kanha, we are aspiring to finish four
                            significant projects in the coming months:
                          </p>
                          {isMobile ? <ProjectTabSection2 /> : <></>}
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3">
                        <Accordion.Header
                          onClick={() => {
                            changeTabName("tab8");
                          }}
                        >
                          Tree rescue via translocation
                        </Accordion.Header>
                        <Accordion.Body>
                          <p>
                            Several trees identified for felling during city
                            development projects have been translocated and
                            rescued.
                          </p>

                          {isMobile ? <ProjectTabSection8 /> : <></>}
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="6">
                        <Accordion.Header
                          onClick={() => {
                            changeTabName("tab1");
                          }}
                        >
                          700k saplings to commemorate India’s best ever
                          Olympics performance of 7 medals
                        </Accordion.Header>
                        <Accordion.Body>
                          <p>
                            India’s Badminton star P V Sindhu launched the
                            nationwide environmental initiative Forests by
                            Heartfulness to celebrate India’s best ever
                            performance at the Tokyo Olympics by planting 7 lakh
                            trees by end of 2021.
                          </p>
                          <p>
                            This drive aims to plant saplings in various land
                            pockets, including schools and colleges, industrial
                            lands, village panchayat lands, private farmer
                            lands, and individual homes. Each sapling will be
                            geo-tagged to monitor and track its growth
                            accurately. Carefully selected trees native to the
                            region and with medicinal values will be a part of
                            the drive. The campaign is open to all. People can
                            join the initiative by volunteering in plantation
                            drives, offering their land for plantation, or
                            adopting the planted trees for a small donation.
                          </p>
                          {isMobile ? <ProjectTabSection1 /> : <></>}
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="7">
                        <Accordion.Header
                          onClick={() => {
                            changeTabName("tab4");
                          }}
                        >
                          Mayuri Eco-Tourism Park, Mahabubnagar
                        </Accordion.Header>
                        <Accordion.Body>
                          <p>
                            In 2018, the Kanha nursery team adopted 6.5 acres of
                            hilly land in Mahabubnagar and created a rainforest
                            for the purpose of Eco-Tourism. 3000 forest variety
                            saplings are planted in the barren land and ecopark
                            is handed over to Mahabubnagar district
                            administration for opening to tourists.
                          </p>
                          {isMobile ? <ProjectTabSection4 /> : <></>}
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="2">
                        <Accordion.Header
                          onClick={() => {
                            changeTabName("tab7");
                          }}
                        >
                          Seed collection drive
                        </Accordion.Header>
                        <Accordion.Body>
                          <p>
                            Seeds are most viable for germination when they are
                            collected and planted fresh. FBH is organizing a
                            neighborhood seed collection drive of native trees
                            and planting them in our nurseries to produce
                            millions of tiny saplings of native trees which will
                            be used in our plantation drives.
                          </p>
                        </Accordion.Body>
                        {isMobile ? <ProjectTabSection9 /> : <></>}
                      </Accordion.Item>
                    </Accordion>
                  </div>
                  {!isMobile ? (
                    <div className="aco-tab-content-section">
                      <div>
                        {(() => {
                          switch (tabName) {
                            case "tab1":
                              return <ProjectTabSection1 />;
                            case "tab2":
                              return <ProjectTabSection2 />;
                            case "tab3":
                              return (
                                <ProjectTabSection3
                                  stateCarousel={stateCarousel}
                                  mapCurrentTab={mapCurrentTab}
                                />
                              );
                            case "tab4":
                              return <ProjectTabSection4 />;
                            case "tab5":
                              return <ProjectTabSection5 />;
                            case "tab6":
                              return <ProjectTabSection6 />;
                            case "tab7":
                              return <ProjectTabSection9 />;
                            case "tab8":
                              return <ProjectTabSection8 />;
                            default:
                              return <></>;
                          }
                        })()}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
